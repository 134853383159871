import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FC } from 'react';
interface SelectWithoutLabelProps {
  data: string[];
  selectedValue: string;
  setValue: (val: string) => void;
  width?: number;
}
export const SelectWithoutLabel: FC<SelectWithoutLabelProps> = ({ setValue, selectedValue, data, width = 120 }) => {
  const handleChange = (event: { target: { value: string } }): void => {
    setValue(event.target.value as string);
  };

  const defaultColor = selectedValue?.toLowerCase() === 'new' ? '#4CAF50' : '#00000099';

  return (
    <Box sx={{ minWidth: 120, height: 42 }}>
      <FormControl
        sx={{
          width: width,
          justifyContent: 'center',
          '& .MuiInputLabel-root': {
            color: defaultColor,
            fontWeight: '400',
            fontFamily: 'Roboto',
            fontSize: 16,
            lineHeight: '16px'
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px'
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F49336'
          }
        }}
      >
        <Select
          sx={{
            height: '40px',
            color: defaultColor,
            background: '#FFFFFF'
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          onChange={handleChange as never}
        >
          {data.map((item) => (
            <MenuItem value={item} key={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

import styles from './DefaultButton.module.css';
import classNames from "classnames";
import {FC} from "react";

type DefaultButtonProps = {
  text: string;
  icon?: string;
  customStyles?: string;
  onClick?: () => void;
  isDisabled?: boolean;
};
const DefaultButton:FC<DefaultButtonProps> = ({
                                                text,
                                                icon,
                                                customStyles = "",
                                                onClick,
                                                isDisabled,
                                              }) => {
  return <button
    className={classNames(styles.btn, {[styles.disabled]: isDisabled}, customStyles)}
    onClick={onClick}
    disabled={isDisabled}
  >
    {icon && <img src={icon} alt=""/>}
    {text.toUpperCase()}
  </button>
}

export default DefaultButton;

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FC } from 'react';
interface BasicSelectProps {
  data: string[];
  selectedValue: string | null;
  setValue: (val: string) => void;
  label?: string;
  width?: number;
  withLabel?: boolean;
}
export const BasicSelect: FC<BasicSelectProps> = ({ setValue, selectedValue, data, label, width = 180, withLabel = true }) => {
  const handleChange = (event: { target: { value: string } }): void => {
    setValue(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120, height: 42 }}>
      <FormControl
        sx={{
          width: width,
          justifyContent: 'center',
          '& .MuiInputLabel-root': {
            color: '#00000099',
            fontWeight: '400',
            fontFamily: 'Roboto',
            fontSize: 16,
            lineHeight: '16px'
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#F49336'
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px'
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F49336'
          }
        }}
      >
        {withLabel && (
          <InputLabel
            id="demo-simple-select-label"
            sx={{
              height: '50px',
              top: -2
            }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          sx={{
            height: '40px',
            color: '#00000099',
            background: '#FFFFFF'
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label={withLabel ? label : undefined}
          onChange={handleChange as never}
        >
          {data.map((item) => (
            <MenuItem value={item} key={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

import { LoginFormValues } from '../../common/types/auth';
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../store/api/base-api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body: LoginFormValues) => ({
        url: 'auth/login',
        method: 'POST',
        body
      })
    }),

    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'POST'
      })
    })
  })
});

export const { useLoginMutation, useLogoutMutation } = authApi;

import {OrderService} from "../common/types/order";

export const formatServices = (orderServices: OrderService[]): string => {
  if (!orderServices || orderServices.length === 0) return "";

  const firstServiceName = orderServices[0].service.serviceName;
  const additionalCount = orderServices.length - 1;

  return additionalCount > 0
    ? `${firstServiceName} + ${additionalCount} more`
    : firstServiceName;
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useState } from 'react';
import { Order } from '../../common/types/order';
import { formatServices } from '../../utils/dataFormat';
import moment from 'moment';
import { SelectWithoutLabel } from '../SelectWithoutLabel';
import { useUpdateOrderMutation } from '../../store/api/orders';
import DetailsModal from '../../components/modals/DetailsModal';
import { Pagination } from '@mui/material';
import './styles.css';
import ArticleIcon from '../../assets/svg/article.svg';

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === 'desc' ? (a: any, b: any) => descendingComparator(a, b, orderBy) : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'ID',
    disablePadding: false,
    label: 'ID'
  },
  {
    id: 'Date',
    disablePadding: false,
    label: 'Date'
  },
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'Address',
    numeric: true,
    disablePadding: false,
    label: 'Address'
  },
  {
    id: 'Phone number',
    numeric: false,
    disablePadding: false,
    label: 'Phone number'
  },
  {
    id: 'Services',
    numeric: true,
    disablePadding: false,
    label: 'Services'
  },
  {
    id: 'Price',
    numeric: true,
    disablePadding: false,
    label: 'Price'
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status'
  }
];

function EnhancedTableHead(props: any) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'none'}
            sortDirection={orderBy === headCell.id ? order : false}
            // @ts-ignore
            sx={{ pl: headCell.id !== 'ID' && '10px', height: '52px' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

interface EnhancedTableProps {
  data: Order[];
  refetchData: () => void;
}
const EnhancedTable: FC<EnhancedTableProps> = ({ data, refetchData }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [pagePagination, setPagePagination] = useState(1);
  const rowsPerPage = 10;
  const [isOpen, toggleModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [updateOrder] = useUpdateOrderMutation();

  const handleRequestSort = (event: any, property: any) => {
    console.log('e', event);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (rowData: any) => {
    setRowData(rowData);
    toggleModal((prev) => !prev);
  };

  const handleChangePage = (event: any, newPage: any) => {
    console.log(event);
    console.log('newPage', newPage);
    setPage(newPage - 1);
    setPagePagination(newPage);
  };

  const handleUpdate = async ({ orderId, status }: {orderId: string, status: string }) => {
    try {
      const response = await updateOrder({ id: orderId, status }).unwrap();
      refetchData();
      console.log('Order updated successfully:', response);
    } catch (error) {
      console.error('Failed to update order:', error);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () => [...data].sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, data]
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TableContainer sx={{ overflowX: 'clip', height: '572px' }}>
          {data.length ? <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort}
                               rowCount={data.length}/>
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{
                      cursor: 'pointer',
                      height: '52px',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      '&:hover': {
                        backgroundColor: 'rgba(244,147,54,0.93)'
                      }
                    }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100px', textOverflow: 'ellipsis'}}
                      onClick={() => handleClick(row)}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{pl: '10px'}}
                               onClick={() => handleClick(row)}
                    >
                      {moment(row.createdAt).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell align="left" padding="none"
                               onClick={() => handleClick(row)}
                               sx={{pl: '10px',
                      maxWidth: '100px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '100px'
                    }}>
                      {row.customer_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      sx={{
                        overflow: 'hidden',
                        pl: '10px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '120px'
                      }}
                      onClick={() => handleClick(row)}
                    >
                      {row.address}
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{pl: '10px',
                      maxWidth: '200px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'}}
                               onClick={() => handleClick(row)}
                    >
                      {row.customer_phone}
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{pl: '10px'}}
                               onClick={() => handleClick(row)}
                    >
                      {row.orderServices.length ? formatServices(row.orderServices) : 'Commercial property'}
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{pl: '10px', whiteSpace: 'nowrap'}}
                               onClick={() => handleClick(row)}
                    >
                      $ {row.price}
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{pl: '10px', width: '100px'}}>
                      <SelectWithoutLabel
                        data={['New', 'Procced']}
                        selectedValue={`${row.status.charAt(0).toUpperCase() + row.status.slice(1)}`}
                        setValue={(value) => handleUpdate({orderId: row.id, status: value.toLowerCase()})}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 52 * emptyRows
                  }}
                >
                  <TableCell colSpan={7} sx={{borderBottom: 'none'}}/>
                </TableRow>
              )}
            </TableBody>
          </Table> : <div className={'empty-container'}>
            <div className={'empty-data-wrapper'}>
              <img src={ArticleIcon} alt={''}/>
              <p className={'empty-data-text'}>No data to display at the moment.</p>
            </div>
          </div>
          }
        </TableContainer>
        <div className={'pagination-container'}>
          <Pagination
            count={Math.ceil(data.length / rowsPerPage)}
            onChange={handleChangePage}
            page={pagePagination}
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  backgroundColor: 'rgba(244,147,54,0.93)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(244,147,54,0.8)'
                  }
                }
              }
            }}
          />
        </div>
      </Box>
      <DetailsModal isOpen={isOpen} toggleModal={toggleModal} data={rowData} />
    </>
  );
};

export default EnhancedTable;

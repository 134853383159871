import styles from './ModalForm.module.css';
import { Button, Modal } from '@mui/material';
import moment from 'moment/moment';
import classNames from 'classnames';

// Modal.setAppElement('#root');

const DetailsModal = ({ isOpen, toggleModal, data }: any) => {
  const closeModal = () => {
    toggleModal(false);
  };

  const serviceNames = data?.orderServices
    .map((serviceItem: any) => serviceItem.service?.serviceName) // Get serviceName
    .filter(Boolean) // Filter out undefined or null values
    .join(', ');
  return (
    <Modal open={isOpen} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.mainWrapper}>
            <div className={styles.wrapper}>
              <p className={styles.header}>Request info</p>
              <div>
                <p className={styles.label}>Services</p>
                <p className={styles.data}>{serviceNames}</p>
              </div>
              <div>
                <p className={styles.label}>Price</p>
                <p className={styles.data}>$ {data?.price}</p>
              </div>
              {data?.message && (
                <div>
                  <p className={styles.label}>Message</p>
                  <p className={classNames(styles.data, styles.ellipsis)}>{data.message}</p>
                </div>
              )}
              <div>
                <p className={styles.label}>Date of request</p>
                <p className={styles.data}> {moment(data?.createdAt).format('MM/DD/YYYY')}</p>
              </div>
              <div>
                <p className={styles.label}>Status</p>
                <p className={classNames(styles.data, { [styles.green]: data?.status.toLowerCase() === 'new' })}>
                  {' '}
                  {`${data?.status.charAt(0).toUpperCase() + data?.status.slice(1)}`}
                </p>
              </div>
            </div>
            <div className={styles.wrapper}>
              <p className={styles.header}>Client info</p>
              <div>
                <p className={styles.label}>Name</p>
                <p className={classNames(styles.data, styles.ellipsis)}> {data?.customer_name}</p>
              </div>
              <div>
                <p className={styles.label}>Address</p>
                <p className={styles.data}> {data?.address}</p>
              </div>
              <div>
                <p className={styles.label}>Email</p>
                <p className={classNames(styles.data, styles.ellipsis)}> {data?.customer_email}</p>
              </div>
              <div>
                <p className={styles.label}>Phone number</p>
                <p className={styles.data}> {data?.customer_phone}</p>
              </div>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Button variant="text" sx={{ color: '#F49336', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500 }} onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailsModal;

import { IAuthStateType, IUser } from '../../common/types/auth';
import {authApi} from "../api/auth";
import {createSlice} from "@reduxjs/toolkit";

const initialState: IAuthStateType = {
  user: {} as IUser,
  isAuth: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogout: (state) => {
      state.isAuth = false;
      state.user = {} as IUser;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    });

    builder.addMatcher(authApi.endpoints.login.matchRejected, (state) => {
      state.isAuth = false;
    });

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.isAuth = false;
      state.user = {} as IUser;
    });
  }
});

export const { userLogout } = authSlice.actions;
export default authSlice.reducer;

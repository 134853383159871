import React from 'react';
import './index.css';
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import {Provider} from "react-redux";
import {Persistor, persistStore} from "redux-persist";
import store from "./store/store";
import ProtectedRoute from "./components/router/ProtectedRoute";
import OrderPage from "./pages/orders/OrderPage";

const persist: Persistor = persistStore(store);

const privateRoutes = [
  {
    path: "/",
    redirectTo: "/login",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/dashboard",
    redirectTo: "/login",
    element: <OrderPage />,
  },
];

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <BrowserRouter>
          <Routes>
            {privateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute redirectTo={route.redirectTo}>
                    {route.element}
                  </ProtectedRoute>
                }
              />
            ))}
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;

import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {userLogout} from "../slices/auth";

const baseQuery = fetchBaseQuery({ baseUrl: 'https://api.nssoftwash.feelit.digital', credentials: 'include' });

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh-token',
        method: 'GET'
      },
      api,
      extraOptions
    );

    if (refreshResult.error && refreshResult.error.status === 400) {
      api.dispatch(userLogout());
    }

    if (refreshResult.error && refreshResult.error.status === 401) {
      api.dispatch(userLogout());
    } else {
      result = await baseQuery(args, api, extraOptions);
    }
  }

  if (result.error && result.error.status === 403) {
    api.dispatch(userLogout());
  }

  return result;
};

export default baseQueryWithReauth;

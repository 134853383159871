import React, { memo } from 'react';
import styles from './LeftDrawer.module.css';
import CompanyIcon from '../../assets/svg/company-icon.svg';
import RequestsIcon from '../../assets/svg/requests-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { userLogout } from '../../store/slices/auth';
import DefaultButton from '../buttons/DefaultButton/DefaultButton';

const LeftDrawer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.auth.user);

  const onLogout = () => {
    dispatch(userLogout());
    navigate('/login');
  };

  return (
    <div className={styles.container}>
      <div className={styles.topItems}>
        <div className={styles.header}>
          <img src={CompanyIcon} alt="" />
          <p className={styles.email}>{userData?.email}</p>
        </div>
        <div className={styles.tabItem}>
          <img src={RequestsIcon} alt="" />
          <p>Requests</p>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <DefaultButton text="Log out" onClick={onLogout} />
      </div>
    </div>
  );
};

export default memo(LeftDrawer);

import { ChangeEvent } from 'react';
import styles from './SearchInput.module.css';
import SearchIcon from '../../../assets/svg/SearchFilled.svg';
import classNames from 'classnames';
const SearchInput = ({ onChange, inputVal }: { onChange: (val: string) => void; inputVal?: string }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div className={styles.container}>
      <input id={'search-input'} className={styles.input} onChange={handleChange} value={inputVal} />
      <label className={classNames(styles.label, { [styles.active]: inputVal?.trim() !== '' })}>Search</label>
      <img src={SearchIcon} alt="" className={styles.searchIcon} />
    </div>
  );
};

export default SearchInput;

import { ChangeEvent, FC, JSX, useEffect, useState } from 'react';
import styles from './OrderPage.module.css';
import Checkbox from '@mui/material/Checkbox';
import { Button, FormControlLabel } from '@mui/material';
import { filterByStatus, filterByTimeRange, filterOrders, TimeRange } from '../../utils/filters';
import {BasicSelect} from "../../components/BasicSelect";
import {useGetAllOrdersQuery} from "../../store/api/orders";
import LeftDrawer from "../../components/LeftDrawer/LeftDrawer";
import SearchInput from "../../components/fields/SearchInput/SearchInput";
import EnhancedTable from "../../components/tables/EnhancedTable";
import {Order} from "../../common/types/order";
import moment from 'moment/moment';

const timeRangeOptions: TimeRange[] = ['All', 'Last 24 hours', 'Last 7 days', 'Last 2 weeks', 'Last month'];

const statusesOptions = ['All', 'New', 'Procced'];

const OrderPage: FC = (): JSX.Element => {
  const { data: responseData, refetch: refetchData } = useGetAllOrdersQuery({});
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState<TimeRange | string>('');
  const [gridData, setGridData] = useState<[]| Order[]>([]);
  const [status, setStatus] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const ordersData = responseData && [...responseData]?.sort((a, b) =>
    moment(b.createdAt).diff(moment(a.createdAt))
  );

  const convertToCSV = (data: { id: any; address: any; price: any; customer_name: any; customer_phone: any; customer_email: any; message: any; commercial: any; createdAt: any; updatedAt: any; service: any; orderServices: any[]; status: any; }[]) => {
    const rows = [];

    const headers = [
      'id',
      'address',
      'price',
      'customer_name',
      'customer_phone',
      'customer_email',
      'message',
      'commercial',
      'createdAt',
      'updatedAt',
      'orderServices',
      'status'
    ];
    rows.push(headers.join(','));

    data.forEach((item: { id: any; address: any; price: any; customer_name: any; customer_phone: any; customer_email: any; message: any; commercial: any; createdAt: any; updatedAt: any; service: any; orderServices: any[]; status: any; }) => {
      const row = [
        item.id,
        item.address,
        item.price,
        item.customer_name,
        item.customer_phone,
        item.customer_email,
        item.message || '',
        item.commercial,
        moment(item.createdAt).format('MM/DD/YYYY'),
        moment(item.updatedAt).format('MM/DD/YYYY'),
        item.orderServices.map((os) => `${os.service.serviceName}, ${os.service.price}`).join(' | '),
        item.status
      ];
      rows.push(row.map((value) => `"${value}"`).join(','));
    });

    return rows.join('\n');
  };
  const downloadCSV = () => {
    [].filter((item) => item);
    // const dataForCSV = isChecked ? ordersData?.filter((item: { commercial: boolean; }) => item.commercial) : ordersData;
    const csv = convertToCSV(ordersData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'users.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const filterData = ({range, status, searchVal, isCommercial}: {range: string, status: string,  searchVal: string, isCommercial: boolean}) => {
    const data = range && status ? filterByStatus(filterByTimeRange(ordersData, range), status) : range ? filterByTimeRange(ordersData, range) : status ?  filterByStatus(ordersData, status) : ordersData;
    const newData = filterOrders(data?.filter((item: Order) =>  isCommercial ? item.commercial : item
    ), searchVal);
    setGridData(newData);
  }
  const onChangeRange = (val: any) => {
    if(val.range) {
      setDateRange (val.range);
    }
    if(val.status) {
      setStatus(val.status);
    }
    setSearchValue(val.searchVal);
    filterData(val);
  };

  useEffect(() => {
    if (ordersData) {
      setGridData(ordersData);
      filterData({range: dateRange, status, searchVal: searchValue, isCommercial: isChecked});

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    filterData({range: dateRange, status, searchVal: searchValue, isCommercial: event.target.checked});
  };

  return (
    <div className={styles.container}>
      <LeftDrawer />
      <div className={styles.dataContainer}>
        <div className={styles.controls}>
          <div className={styles.filterContainer}>
            <SearchInput onChange={(val) => onChangeRange({range: dateRange, status, searchVal: val, isCommercial: isChecked})} inputVal={searchValue} />
            {/*// @ts-ignore*/}
            <BasicSelect data={timeRangeOptions} label={'Date'} setValue={(val) => onChangeRange({range: val, status, searchVal: searchValue, isCommercial: isChecked})} selectedValue={dateRange} />
            {/*// @ts-ignore*/}
            <BasicSelect data={statusesOptions} label={'Statuses'} setValue={(val) => onChangeRange({status: val, range: dateRange, searchVal: searchValue, isCommercial: isChecked})} selectedValue={status} />
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: '#000000DE',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Roboto'
                },
                '& .MuiCheckbox-root.Mui-checked': {
                  color: '#F49336'
                }
              }}
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} inputProps={{ 'aria-label': 'controlled-checkbox' }} />}
              label="Only commercial property"
            />
          </div>
          <div>
            <Button
              onClick={downloadCSV}
              variant="outlined"
              sx={{
                color: '#F49336',
                borderColor: '#F4933680',
                fontWeight: '400',
                fontSize: '15px',
                fontFamily: 'Roboto',
                '&:hover': {
                  backgroundColor: '#F49336',
                  color: 'white'
                }
              }}
            >
              Export to csv
            </Button>
          </div>
        </div>
        <EnhancedTable data={gridData} refetchData={refetchData} />
      </div>
    </div>
  );
};

export default OrderPage;

import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../store/api/base-api';

export const ordersApi = createApi({
  reducerPath: 'opportunities',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => 'orders'
    }),

    updateOrder: builder.mutation({
      query: ({ id, ...updates }) => ({
        url: `orders/${id}`, // Assuming the endpoint uses an `id` parameter
        method: 'PATCH', // Use PATCH for partial updates
        body: updates // Include only fields to update in the request body
      })
    })
  })
});

export const { useGetAllOrdersQuery, useUpdateOrderMutation } = ordersApi;

import styles from './LoginPage.module.css';
import Logo from '../../assets/svg/logo.svg';
import { ChangeEvent, useState } from 'react';
import {useNavigate} from "react-router-dom";
import DefaultButton from "../../components/buttons/DefaultButton/DefaultButton";
import {useLoginMutation} from "../../store/api/auth";
import { IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
const LoginPage = () => {
  const [login] = useLoginMutation();
  const navigate = useNavigate();

  const [loginValue, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showPassword, togglePass] = useState(false);

  const handleChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (loginError) {
      setLoginError("");
    }
    setLogin(inputValue);
  };

  const onClickLogin = async () => {
    await login({
      email: loginValue,
      password: password
    })
      .unwrap()
      .then(() => {
        navigate('/dashboard');
      })
      .catch((error: unknown) => {
        console.log('Error ', error);
        setLoginError('Invalid email or password. Please try again.');
      });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (loginError) {
      setLoginError("");
    }
    setPassword(event.target.value);
  };
  const handleClickShowPassword = () => togglePass(prev => !prev);

  return <div className={styles.main}>
    <div className={styles.container}>
      <img src={Logo} alt={''} className={styles.logo} />
      <h5 className={styles.header}>Welcome to admin panel</h5>
      <div className={styles.inputsWrapper}>
        <TextField
          id="outlined-basic"
          label="Login"
          variant="outlined"
          value={loginValue}
          onChange={handleChangeLogin}
          error={!!loginError}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'gray', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#f38d2d', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#F49336', // Border color when focused
              },
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: '#F49336', // Change label color when focused
            },
            fontFamily: 'Roboto',
            fontSize: '16px'
          }}
        />
        <FormControl variant="outlined"
                     sx={{
                       '& .MuiInputLabel-root': {
                         color: loginError ? '#D32F2F' : '#00000099',
                       },
                       '& .MuiInputLabel-root.Mui-focused': {
                         color: '#F49336',
                       },
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: 'gray',
                         },
                         '&:hover fieldset': {
                           borderColor: '#f38d2d',
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#F49336',
                         },
                       },
                     }}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange}
            error={!!loginError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? 'hide the password' : 'display the password'
                  }
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <p className={styles.errorMessage}>{loginError}</p>
      </div>
      <DefaultButton text={'Login'} onClick={onClickLogin} isDisabled={!loginValue || !password || !!loginError} />
    </div>
  </div>
}

export default LoginPage;

import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useAppSelector} from "../../store/store";

type ProtectedRouteProps = {
  redirectTo: string;
  children: any;
};
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  redirectTo = "/login",
  children,
}) => {
  const isAuth = useAppSelector((state) => state.auth.isAuth);

  if (!isAuth) {
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

export type TimeRange = 'Last 24 hours' | 'Last 7 days' | 'Last 2 weeks' | 'Last month' | 'All';
export type Status = 'All' | 'New' | 'Proceed';
export const filterByTimeRange = (data: any[], range: TimeRange | string): any[] => {
  const now = new Date();
  let startDate: Date;
  if (range === 'All') {
    return data;
  }

  switch (range) {
    case 'Last 24 hours':
      startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 1 day ago
      break;
    case 'Last 7 days':
      startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
      break;
    case 'Last 2 weeks':
      startDate = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000); // 14 days ago
      break;
    case 'Last month':
      startDate = new Date(now);
      startDate.setMonth(now.getMonth() - 1); // 1 month ago
      break;
    default:
      throw new Error('Invalid time range');
  }

  return data.filter((item) => new Date(item.createdAt) >= startDate);
};

export const filterByStatus = (data: any[], status: string): any[] => {
  if (status === 'All') {
    return data;
  }

  return data.filter((item) => item.status === status.toLowerCase());
};

export const filterOrders = (data: any, searchInput: any) => {
  if (!searchInput.trim()) {
    return data;
  }

  const lowerCasedInput = searchInput.toLowerCase();

  return data.filter(
    (order: any) =>
      order.id.toLowerCase().startsWith(lowerCasedInput) ||
      order.customer_name.toLowerCase().startsWith(lowerCasedInput) ||
      order.address.toLowerCase().startsWith(lowerCasedInput)
  );
};
